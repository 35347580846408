// React
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// FutureLab
import { ChatListDataInterface } from './ChatListItemsDesktop';
import { LoggedInUserDetails } from '../../reducers/index';
import { UserProfileDetails } from '../../pages/AddChatPage';
import { getQueryResults } from '../../utilities/Chat';
import { RootState } from '../../reducers/index';
import { ChatListChatRequest } from './ChatListChatRequest';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { store } from '../../ReduxRoot';

// UI
import { Button, ButtonToolbar, FlexboxGrid } from 'rsuite';

interface ChatListChatRequestComponentsInterface {
	pendingChatListsItems: ChatListDataInterface[];
	loggedInUserDetail: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	filterQuery: any;
}

export function ChatListChatRequestLoop(
	ChatListChatRequestComponentsInterface: ChatListChatRequestComponentsInterface
) {
	const { pendingChatListsItems, loggedInUserDetail, userListProfilesDetails, filterQuery } =
		ChatListChatRequestComponentsInterface;
	const pendingApprovalChatLists = useSelector((state: RootState) => state.pendingApprovalChatList);
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<>
			{store.getState().pendingApprovalChatList.length > 0 ? (
				<>
					{getQueryResults(pendingApprovalChatLists, loggedInUserDetail, userListProfilesDetails, filterQuery)
						.length > 0 ? (
						<div
							className={
								getWindowSize(windowSize.width) === windowSizeType.XS
									? 'chat-list-height overflow-y-auto'
									: 'chat-list-height overflow-y-auto'
							}
						>
							{getQueryResults(
								pendingApprovalChatLists,
								loggedInUserDetail,
								userListProfilesDetails,
								filterQuery
							).map((pendingApprovalChatList: ChatListDataInterface, index: number) => {
								return (
									<ChatListChatRequest
										key={index}
										pendingChatListsItem={pendingApprovalChatList}
										userListProfilesDetails={userListProfilesDetails}
									></ChatListChatRequest>
								);
							})}
						</div>
					) : (
						<FlexboxGrid className="px-5 pt-10">
							<FlexboxGrid.Item colspan={24}>
								<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
									No result found.
								</FlexboxGrid.Item>
								<FlexboxGrid.Item className="text-base text-center text-gray-600">
									Please refine your key words and try again
								</FlexboxGrid.Item>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					)}
				</>
			) : (
				<FlexboxGrid className="px-5 pt-10">
					<FlexboxGrid.Item colspan={24}>
						<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
							Send request chat to someone
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base text-center text-gray-600">
							You don't have any request currently. Once you have booked a session with your mentor, it
							will appear here
						</FlexboxGrid.Item>
						<ButtonToolbar className="text-center pt-5 pb-3">
							<Button
								appearance="primary"
								target="_blank"
								href="https://futurelab.global/discover/mentors?lang=en"
							>
								<span className="font-normal">Book a Session</span>
							</Button>
						</ButtonToolbar>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
		</>
	);
}
