// React
import * as React from 'react';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './configureStore';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import Loader from './components/common/Loader';

// FutureLab
import App from './App';

// UI
import { Typography } from '@material-ui/core';

export const { persistor, store } = configureStore();
const isProduction = process.env.NODE_ENV === 'production';
export function ReduxRoot() {
	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</CacheBuster>
	);
}
