// React
import React, { useEffect, useState } from 'react';

// UI
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Button, ButtonToolbar, FlexboxGrid } from 'rsuite';

// FutureLab
import { ChatListDataInterface } from './ChatListItemsMobile';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { getQueryResults } from '../../utilities/Chat';
import { ChatListItemsMobileLoop } from './ChatListItemsMobileLoop';
import { ChatListItemsDesktopLoop } from './ChatListItemsDesktopLoop';
import { LoggedInUserDetails } from '../../reducers/index';
import { UserProfileDetails } from '../../pages/AddChatPage';
import useIntersection from '../../hooks/useIntersection';

interface ChatListItemsMessages {
	loggedInUserDetail: LoggedInUserDetails;
	userListProfilesDetails: UserProfileDetails[];
	chatListsItems: ChatListDataInterface[];
	filterQuery: any;
	setFilterQuery: any;
	callbackScroll: () => void;
}

export function ChatList(ChatListItemsMessages: ChatListItemsMessages) {
	const { loggedInUserDetail, userListProfilesDetails, filterQuery, chatListsItems, callbackScroll } =
		ChatListItemsMessages;
	const chatsListRef = React.useRef(null);
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	const [targetRef, intesecting] = useIntersection({
		root: chatsListRef.current,
		threshold: 0.8,
		callback: callbackScroll,
	});

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{chatListsItems.length > 0 ? (
				<div>
					{getQueryResults(chatListsItems, loggedInUserDetail, userListProfilesDetails, filterQuery).length >
					0 ? (
						getWindowSize(windowSize.width) === windowSizeType.XS ? (
							<div ref={chatsListRef} className="overflow-y-auto chat-list-height">
								<ChatListItemsMobileLoop
									chatListsItems={chatListsItems}
									loggedInUserDetails={loggedInUserDetail}
									userListProfilesDetails={userListProfilesDetails}
									filterQuery={filterQuery}
									targetRef={targetRef}
								/>
							</div>
						) : (
							<div ref={chatsListRef} className="overflow-y-auto chat-list-height">
								<ChatListItemsDesktopLoop
									chatListsItems={chatListsItems}
									loggedInUserDetails={loggedInUserDetail}
									userListProfilesDetails={userListProfilesDetails}
									filterQuery={filterQuery}
									targetRef={targetRef}
								/>
							</div>
						)
					) : (
						<FlexboxGrid className="px-5 pt-10">
							<FlexboxGrid.Item colspan={24}>
								<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
									No result found.
								</FlexboxGrid.Item>
								<FlexboxGrid.Item className="text-base text-center text-gray-600">
									Please refine your key words and try again
								</FlexboxGrid.Item>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					)}
				</div>
			) : (
				<FlexboxGrid className="px-5 pt-10">
					<FlexboxGrid.Item colspan={24}>
						<FlexboxGrid.Item className="font-bold text-xl text-center text-gray-900 pb-3">
							It's nice to chat with someone
						</FlexboxGrid.Item>
						<FlexboxGrid.Item className="text-base text-center text-gray-600">
							No chatroom in your inbox, yet! Start to book a session with your mentor.
						</FlexboxGrid.Item>
						<ButtonToolbar className="text-center pt-5 pb-3">
							<Button
								appearance="primary"
								target="_blank"
								href="https://futurelab.global/discover/mentors?lang=en"
							>
								<span className="font-normal">Book a Session</span>
							</Button>
						</ButtonToolbar>
					</FlexboxGrid.Item>
				</FlexboxGrid>
			)}
			{/* <Fab className="add-group-chat-button" aria-label="add" onClick={() => history.push(router.addChat().$)}>
				<Add />
			</Fab> */}
		</>
	);
}
